import React from 'react'
import { Provider } from 'use-http';

import 'isomorphic-fetch'
import 'url-search-params-polyfill';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

const BASE_URL = process.env.USE_DEV_API * 1
  ? process.env.DEV_API_HOST
  : `https://${process.env.APP_HOST}${process.env.API_PATH}`

export default ({ children, options }) => (
  <Provider url={BASE_URL} options={options}>
    {children}
  </Provider>
)
