import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import {
  fontSize,
  fontWeight,
  lineHeight,
  space,
  color,
  letterSpacing,
  display,
  size,
} from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop'
import { Link as GatsbyLink } from 'gatsby';
import isString from 'lodash/isString'

import { customColor } from './utils/getColor';

const linkStyle = css`
  cursor: pointer;
  ${fontSize}
  ${size}
  ${space}
  ${color}
  ${fontWeight}
  ${lineHeight}
  ${letterSpacing}
  ${display}
  text-decoration: none;
  ${({ disabled }) => disabled && `
    pointer-events: none;
  `}
  &:hover {
    ${customColor('hoverColor')};
  }
`;

const NomalLink = styled.a.withConfig({ shouldForwardProp })`
  ${linkStyle}
`;

const GatsbyStyledLink = styled(GatsbyLink).withConfig({ shouldForwardProp })`
  ${linkStyle}
`;

const Link = forwardRef(({ to, button, title, ...props }, ref) => {
  const tit = title || (isString(props.children) ? props.children : '')
  if (to) {
    return (
      <GatsbyStyledLink to={to} title={tit} ref={ref} {...props} />
    );
  }
  return (
    <NomalLink
      as="a"
      target="_blank"
      title={tit}
      ref={ref}
      { ...props }
    />
  );
});

Link.displayName = 'Link';

Link.defaultProps = {
  // fontWeight: 'normal',
  color: 'white',
};

export default Link;
