import React from 'react'
import { Menu, MenuButton, MenuList, MenuLink } from '@reach/menu-button'
import "@reach/menu-button/styles.css";
import styled from 'styled-components'
import Collapsible from 'react-collapsible';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'

import useResponsive from 'contexts/mediaQuery/useResponsive'

import Link from 'components/Link';
import Flex from 'components/Flex';
import Box from 'components/Box';

import MenuBox from './MenuBox'

const StyledMenuButton = styled(MenuButton)`
  background-color: transparent;
  border: none;
  font-size: 1em;
  line-height: 1.5;
  padding: 0 0.75em;
`;

const StyledMenuList = styled(MenuList)`
margin-top: 0.75em;
a[data-reach-menu-item] {
  background-color: transparent;
  font-size: 1rem;
  line-height: 2;
  color: black;
}
`

const DD = ({ isOpen, children, ...props }) => (
  <MenuBox as={Flex} display="flex" {...props}>
    {children}
    <Box flex="1" />
    <Box px="1em">
      {isOpen ? <FaCaretUp /> : <FaCaretDown />}
    </Box>
  </MenuBox>
)

const Dropdown = ({ list, label, onClick }) => {
  const { isTablet } = useResponsive()
  return isTablet ? (
    <Collapsible
      trigger={<DD>{label}</DD>}
      triggerWhenOpen={<DD isOpen>{label}</DD>}
    >
      <Box ml="1.5em">
        {list.map((l, i) => (
          <MenuBox key={i}>
            <Link display="block" width={1} color="darkPurple" onClick={onClick} {...l} />
          </MenuBox>
        ))}
      </Box>
    </Collapsible>
  ) : (
    <Menu>
      {({ isExpanded }) => (
        <>
          <StyledMenuButton>
            {label}
            {isExpanded ? <FaCaretUp style={{ verticalAlign: 'text-top' }} /> : <FaCaretDown style={{ verticalAlign: 'text-top' }} />}
          </StyledMenuButton>
          <StyledMenuList>
            {list.map((l, i) => <MenuLink as={Link} {...l} key={i} />)}
          </StyledMenuList>
        </>
      )}
    </Menu>
  )
}

export default Dropdown
