import React, { useState } from 'react'
import { TiHomeOutline } from 'react-icons/ti';
import { MdMenu } from 'react-icons/md';
import { IoIosClose } from 'react-icons/io';
import { navigate } from "gatsby"

import useResponsive from 'contexts/mediaQuery/useResponsive'

import Box from 'components/Box';
import Flex from 'components/Flex';
import Image from 'components/Image';
import Text from 'components/Text';
import Button from 'components/Button';
import Link from 'components/Link';
import theme, { responsive } from 'components/ThemeProvider/theme';

import logo from './logo.png';
import Dropdown from './Dropdown'
import MenuBox from './MenuBox'

const headerNames = [
  {
    name: '公告訊息',
    to: '/news'
  },
  {
    name: '各區調會資訊',
    to: '/offices'
  },
  {
    name: '相關表單',
    to: '/download'
  },
  {
    name: '線上聲請調解',
    to: '/apply'
  },
  {
    name: '聲請事項查詢',
    sub: [
      {
        children: '調解案件進度查詢',
        to: '/progress'
      },
      {
        children: '聲請調解不成立證明',
        to: '/claim/proof'
      },
      {
        children: '聲請閱卷',
        to: '/claim/scoring'
      },
      {
        children: '補正文件上傳',
        to: '/attach'
      },
    ]
  },
  {
    name: '問卷調查',
    to: '/survey'
  },
]

const Header = ({ siteTitle, ...props }) => {
  const { isTablet } = useResponsive()
  const [open, setOpen] = useState(false)
  return (
    <Flex
      as="header"
      bg="white"
      position="fixed"
      borderBottom="1px solid"
      top={0}
      left={0}
      right={0}
      alignItems="center"
      justifyContent="space-between"
      px={responsive('0', '2em')}
      zOrder={2}
      zIndez={99}
      {...props}
    >
      <Flex as={(p) => <Link color="text" {...p} />} to="/" alignItems="center" pl={responsive('1.25em', 0)}>
        <Box width={responsive('1.5em', '2em')}>
          <Image src={logo} alt="新北市政府Logo" />
        </Box>
        <Box px="0.5em" fontSize={responsive('0.8265em', '1em')}>
          <Text>新北市政府法制局</Text>
          <Text>調解行政作業管理系統</Text>
        </Box>
      </Flex>
      <Box>
        {isTablet ? (
          <Box>
            <Button.Transparent onClick={() => setOpen(!open)}>
              <MdMenu size="3.25em" />
            </Button.Transparent>
            {open && (
              <Box position="absolute" top={theme.headerHeight} height="100vh" left="0" right="0" bg="black" opacity="0.5" />
            )}
            <Flex
              width="84.5%"
              right="0"
              top={theme.headerHeight}
              height={`calc(100vh - ${theme.headerHeight[0]})`}
              bg="white"
              position="absolute"
              transform={open ? 'translateX(0)' : 'translateX(100%)'}
              transition="transform 0.5s"
              flexDirection="column"
            >
              <Box flex="1" overflow="scroll">
                <Box textAlign="right" pr="1em" pt="1em">
                  <Button.Transparent onClick={() => setOpen(false)} display="inline-block" color="white" bg="darkPurple" lineHeight="0" px="0" py="0">
                    <IoIosClose size="2.5rem" />
                  </Button.Transparent>
                </Box>
                <Box pb="2em" pt="0.5em">
                  {headerNames.map(({ name, to, sub }, i) => sub ? (
                    <Dropdown
                      key={i}
                      list={sub}
                      label={name}
                      onClick={() => setOpen(false)}
                    />
                  ) : (
                    <MenuBox key={i}>
                      <Link
                        color="darkPurple"
                        display="block"
                        width={1}
                        to={to}
                        fontWeight="bold"
                        onClick={() => setOpen(false)}
                      >{name}</Link>
                    </MenuBox>
                  ))}
                  <Box mt="1em" textAlign="center">
                    <Button
                      onClick={() => {
                        setOpen(false)
                        navigate('/')
                      }}
                      leftIcon={TiHomeOutline}
                      fontSize="1.125em"
                    >
                      回首頁
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Box>
        ) : (
          <Box>
            <Box px="0.75em" mb="0.5em" textAlign="right">
              <Button.Transparent
                as={(p) => <Link color="text" {...p} />}
                to="/"
                px="0"
                py="0"
                hoverBg="transparent"
                hoverColor="text"
                leftIcon={TiHomeOutline}
                fontSize="1em"
                verticalAlign="bottom"
              >
                回首頁
              </Button.Transparent>
            </Box>
            <Flex flex="1" justifyContent="flex-end">
              {headerNames.reduce((all, { name, to, sub }, i) => {
                const ele = sub ? <Dropdown key={i} list={sub} label={name} /> : (
                  <Link
                    key={i}
                    color="text"
                    to={to}
                    px="0.75em"
                  >{name}</Link>
                )
                return i ? [...all, <Box key={`div-${i}`} height="1.5em" borderLeft="1px solid" />, ele] : [...all, ele]
              }, [])}
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default Header
