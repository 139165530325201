/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import WebFont from 'webfontloader';
import { setLocale } from 'yup';
import { enableES5 } from 'immer'

import wrapWithProvider from './with-provider';
import messages from './src/utils/messages'

if (!window.crypto) {
  window.crypto = window.msCrypto
}

enableES5()

setLocale({
  mixed: {
    required: messages.required,
  },
  string: {
    length: messages.length,
    email: messages.email,
  },
});

WebFont.load({
  custom: {
    families: ['gidole'],
    urls: ['/webfont/gidole/stylesheet.css']
  }
});

export const wrapRootElement = wrapWithProvider;
